import React from 'react';
import Layout from '../../components/Layout';
import PricingServices from '../home/PricingServices';
function Blog() {
    return (
        <Layout className="px-20 py-5 bg-white">
          <PricingServices/>
          
        </Layout>
    );
}

export default Blog;