import React from 'react';
import Layout from '../../components/Layout';
import Aboutus from '../home/Aboutus';
function About() {
    return (
        <Layout>
           <Aboutus/>
        </Layout>
    );
}

export default About;