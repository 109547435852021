import React from "react";
import { FaArrowUp } from "react-icons/fa";

function ScrollUp() {
    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    return (
        <div className="fixed bottom-0 right-0 px-6 mb-3 text-black" onClick={scrollToTop}>
            <button className="border-2 rounded-full p-2 text-black"><FaArrowUp className="text-black p-1" /></button>
        </div>
    );
}

export default ScrollUp;